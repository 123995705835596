<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">OPEN COOP PROTEK SAVINGS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                readonly
                v-model="branch"
                label="Branch"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_of_member"
                label="Date of Opening"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="account_no"
                label="Account No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="place_of_birth"
                      dense
                      label="Place of Birth"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="tin_no_personal"
                      dense
                      outlined
                      label="Tax ID Number (TIN)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="sss_no"
                      dense
                      outlined
                      label="SSS Number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="religion"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="no_of_dependents"
                      dense
                      outlined
                      label="No. Of Dependents"
                      type="number"
                      min="0"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="educational_attainment"
                      dense
                      outlined
                      label="Educational Attainment"
                      :items="['Elementary Level','Secondary Level','College Level','College Graduate']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="present_address"
                      dense
                      outlined
                      label="Present Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-select
                      v-model="residence_is"
                      dense
                      outlined
                      label="Residence is"
                      :items="['Owned','Rented','Mortgaged','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-if="residence_is==='Others'"
                      v-model="residence_specify"
                      dense
                      outlined
                      label="Specify (if others)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-model="permanent_address"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="zip_code"
                      dense
                      outlined
                      label="Zip Code"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="citizenship"
                      dense
                      outlined
                      label="Citizenship"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="contact_no"
                      dense
                      outlined
                      type="number"
                      label="Contact Number/s"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="email_address_personal"
                      dense
                      outlined
                      label="Email Address"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col md="4" cols="12">
                <v-avatar
                  rounded
                  size="300"
                  class="me-6"
                >
                  <v-img :src="cropped"></v-img>
                </v-avatar>
                <v-btn
                  color="primary"
                  class="me-3 mt-5"
                  @click="$refs.refInputEl.click()"
                >
                  <v-icon class="d-sm-none">
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span class="d-none d-sm-block">Valid ID & Signature Member 1</span>
                </v-btn>
                <input
                  ref="refInputEl"
                  type="file"
                  accept=".jpeg,.png,.jpg"
                  :hidden="true"
                  v-on:click="$refs.refInputEl.value = ''"
                  v-on:change="croppie"
                />
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-if="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_loan_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      avatarImg: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_1: '',
      cropped: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_2: '',
      cropped_bus: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_3: '',
      cropped_home: require('@/assets/images/avatars/1.png'),

      type_of_account: '',
      date_of_member: '',
      account_name: '',
      account_no: '',

      search: '',
      search_member: '',
      search_items: [],
      search2: null,
      search_member2: '',
      search_items2: [],
      search3: null,
      search_member3: '',
      search_items3: [],
      search_corporate: null,
      search_items_corporate: [],


      last_name: '',
      first_name: '',
      middle_name: '',
      permanent_address: '',
      date_of_birth: '',
      place_of_birth: '',
      gender: '',
      tin_no_personal: '',
      sss_no: '',
      religion: '',
      civil_status: '',
      no_of_dependents: 0,
      educational_attainment: '',
      present_address: '',
      residence_is: '',
      residence_specify: '',
      zip_code: '',
      citizenship: '',
      contact_no: '',
      email_address_personal: '',

      last_name2: '',
      first_name2: '',
      middle_name2: '',
      permanent_address2: '',
      date_of_birth2: '',
      place_of_birth2: '',
      gender2: '',
      tin_no_personal2: '',
      sss_no2: '',
      religion2: '',
      civil_status2: '',
      no_of_dependents2: 0,
      educational_attainment2: '',
      present_address2: '',
      residence_is2: '',
      residence_specify2: '',
      zip_code2: '',
      citizenship2: '',
      contact_no2: '',
      email_address_personal2: '',

      last_name3: '',
      first_name3: '',
      middle_name3: '',
      permanent_address3: '',
      date_of_birth3: '',
      place_of_birth3: '',
      gender3: '',
      tin_no_personal3: '',
      sss_no3: '',
      religion3: '',
      civil_status3: '',
      no_of_dependents3: 0,
      educational_attainment3: '',
      present_address3: '',
      residence_is3: '',
      residence_specify3: '',
      zip_code3: '',
      citizenship3: '',
      contact_no3: '',
      email_address_personal3: '',

      type_of_account_corporate: '',
      business_address: '',
      date_of_registration: '',
      place_of_registration: '',
      registration_no: '',
      tin_no: '',
      nature_of_business: '',
      gross_annual_income: 0,
      telephone_no: '',
      fax_no: '',
      email_address: '',
      authorized_representative_name: '',
      authorized_representative_contact_no: '',
      financial_details_gscc: '',
      financial_details_other_banks: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.account_no_data()
    },
    data() {
      return initialState()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      search_member2: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member2 == '') {
            this.search_items2 = []
          } else {
            this.searching_search_items2(this.search_member2)
          }
        }, 500)
      },
      search_member3: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member3 == '') {
            this.search_items3 = []
          } else {
            this.searching_search_items3(this.search_member3)
          }
        }, 500)
      },
      type_of_account: function () {
        this.account_name = ''
        if (this.type_of_account === 'Personal' || this.type_of_account === 'Junior') {
          this.search2 = null
          this.search3 = null
        } else if (this.type_of_account === 'Joint') {
          this.search3 = null
        }
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'branch', 'branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_members', 'search_all_members', 'search_corporate_account']),
      ...mapActions('coop_protek_information', ['create_coop_protek_info']),
      reset() {
        Object.assign(this.$data, initialState())

        this.account_no_data()
      },
      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage_mem_1 = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      croppie_bus(e) {
        this.show_bus = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_bus = this.croppieImage_mem_2 = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      croppie_home(e) {
        this.show_home = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_home = this.croppieImage_mem_3 = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg

        this.$refs.refInputEl_bus.value = ''
        this.cropped_bus = this.avatarImg

        this.$refs.refInputEl_home.value = ''
        this.cropped_home = this.avatarImg
      },
      account_no_data() {
        this.account_no = 'GCSP-' + moment().year() + '-' + moment().seconds() + '' + moment().minutes() + '' + moment().hours() + '-' + moment().date() + '' + (moment().month() + 1)
      },

      save_loan_application() {
        this.saving = true
        this.alert = false
        var flag = false
        if (moment(this.date_of_member, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
          || moment(this.date_of_member, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
          flag = true
        } else {
          var compareDate = moment(this.date_of_member, 'YYYY-MM-DD')
          var startDate = moment(this.month_start, 'MMMM D, YYYY')
          var endDate = moment(this.month_end, 'MMMM D, YYYY')

          flag = compareDate.isBetween(startDate, endDate) //false in this case
        }
        if (flag === false) {
          this.date_of_member = ''
          this.alert = true
          this.alert_message = 'Date of Opening is Not Belong in Transaction Month!'
          this.saving = false
        } else {
          var proceed = true;
          var account_nameeee = this.last_name.toUpperCase() + ', ' + this.first_name.toUpperCase() + ' ' + this.middle_name.toUpperCase();
          if (this.croppieImage_mem_1 === '') {
            this.alert = true
            this.alert_message = 'Please Upload Valid ID with Signature of Member 1'
            this.saving = false
            proceed = false
          }
          if (proceed) {
            if (this.$refs.form.validate()) {
              const data = new FormData()
              data.append('branch_id', this.branch_id);
              data.append('account_name', account_nameeee);
              data.append('account_no', this.account_no);
              data.append('last_name', this.last_name.toUpperCase());
              data.append('first_name', this.first_name.toUpperCase());
              data.append('middle_name', this.middle_name.toUpperCase());
              data.append('date_of_effective', this.date_of_member);
              data.append('month_of', this.month_of);
              data.append('member_1_valid_signature', this.croppieImage_mem_1);
              data.append('contact_no', this.contact_no);
              this.create_coop_protek_info(data)
                .then(response => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: response.data,
                  })
                  this.reset()
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  console.log(error)
                  this.saving = false
                })
            } else {
              this.alert = true
              this.alert_message = 'Please Fill up the field/s'
              this.saving = false
            }
          }
        }
      }
    }
  }
</script>
